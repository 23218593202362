import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

function Contact() {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        message: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [csrfToken, setCsrfToken] = useState('');

    useEffect(() => {
        // Fetch the CSRF token on component mount
        const fetchCsrfToken = async () => {
            try {
                const response = await axios.get('/csrftoken'); // Adjust the endpoint to match your server
                setCsrfToken(response.headers['x-csrf-token']);
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        };

        fetchCsrfToken();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setErrorMessages([]);

        try {
            const response = await axios.post('/feedback', {
                username: formData.username,
                fromEmail: formData.email,
                feedbackText: formData.message
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Csrf-Token': csrfToken // Include the CSRF token in the request headers
                }
            });

            if (response.status === 200) {
                setSubmitted(true);
            } else {
                setErrorMessages(['An unexpected error occurred. Please try again.']);
            }
        } catch (error) {
            if (error.response && error.response.data.errors) {
                const validationErrors = error.response.data.errors.map(err => err.msg);
                setErrorMessages(validationErrors); // Display the validation error messages
            } else {
                setErrorMessages(['There was an error submitting your feedback. Please try again.']);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <section className="position-relative py-4 py-xl-5">
            <div className="container position-relative">
                <div className="row mb-5">
                    <div className="col-md-8 col-xl-6 text-center mx-auto">
                        <h2>Contact Us</h2>
                        <div className="d-flex justify-content-center align-items-center mb-3">
                            <FontAwesomeIcon icon={faComments} size="2x" style={{ color: '#FFCC00' }} />
                        </div>
                        <p className="w-lg-50 mx-auto">
                            Have questions or need assistance? We're here to help! Feel free to reach out to us, and we’ll get back to you as soon as possible.
                        </p>
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-6 col-lg-5 col-xl-4">
                        <div>
                            {!submitted ? (
                                <form className="p-3 p-xl-4" onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="username"
                                            placeholder="Name"
                                            value={formData.username}
                                            onChange={handleChange}
                                            required
                                            maxLength="50"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <input
                                            className="form-control"
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                            maxLength="50"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <textarea
                                            className="form-control"
                                            name="message"
                                            rows="6"
                                            placeholder="Message"
                                            value={formData.message}
                                            onChange={handleChange}
                                            required
                                            maxLength="500"
                                        />
                                    </div>
                                    <div className="mt-3">
                                        {errorMessages.length > 0 && (
                                            <div className="message-box bs-body-color">
                                                {errorMessages.map((msg, index) => (
                                                    <div key={index}>{msg}</div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <button className="btn btn-primary d-block w-100" type="submit" disabled={isLoading}>
                                            {isLoading ? 'Sending...' : 'Send'}
                                        </button>
                                    </div>
                                </form>
                            ) : (
                                <div className="text-center">
                                    <FontAwesomeIcon icon={faCheckCircle} size="3x" style={{ color: '#FFCC00' }} />
                                    <h4 className="mt-3">Thank you for your feedback!</h4>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;
