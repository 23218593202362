import { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faThumbsUp, faThumbsDown, faTrophy, faRedo, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { fetchImages } from '../utils/fetchImages';


// Timer component
function Timer({ seconds }) {
    return (
        <div className="timer">
            <div className="timer-circle">{seconds}</div>
        </div>
    );
}

// Timer component for small devices
function TimerSmall({ seconds }) {
    return (
        <div className="timer">
            <div className="timerSmall-circle">{seconds}</div>
        </div>
    );
}

// Loading Circle component
function LoadingCircle() {
    return (
        <div className="loading-circle">
            <div className="loading-circle-inner"></div>
        </div>
    );
}

// Function to shuffle an array (Fisher-Yates algorithm)
function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

function AmazonReferral() {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASEURL}/products`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const products = await response.json();

                if (!Array.isArray(products) || products.length === 0) {
                    throw new Error('No products found');
                }

                // Shuffle products
                shuffle(products);

                // Set the products to state
                setProducts(products);
            } catch (error) {
                console.error('Error fetching Amazon referral products:', error);
            }
        };

        fetchProducts();
    }, []);

    if (products.length === 0) {
        return null;
    }

    return (
        <div id="amazonReferralCarousel" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                {products.map((product, index) => (
                    <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={product._id}>
                        <div className="amazon-referral">
                            <div className="referral-content text-center">
                                <h2>{product.productName}</h2>
                                <img
                                    src={product.productImage}
                                    alt={product.productDescription}
                                    className="referral-image d-block mx-auto"
                                />
                                <p className="referral-text">{product.productDescription}</p>
                                <a
                                    href={product.referralLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="referral-button btn btn-primary"
                                >
                                    Shop Now
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <a className="carousel-control-prev" href="#amazonReferralCarousel" role="button" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#amazonReferralCarousel" role="button" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
            </a>
        </div>
    );
}

function QuizGame() {
    const [questions, setQuestions] = useState([]);
    const [quizCardImages, setQuizCardImages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
    const [timer, setTimer] = useState(10);
    const [answerLog, setAnswerLog] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isProcessingResult, setIsProcessingResult] = useState(false);
    const [result, setResult] = useState(null);
    const [hasSubmitted, setHasSubmitted] = useState(false); // Flag to track submission
    const [csrfToken, setCSRF] = useState(null);
    const [clickHandled, setClickHandled] = useState(false); // Tracks if an answer has been clicked for the current question
    const [transitioning, setTransitioning] = useState(false); // Tracks if the app is transitioning between questions
    const debouncedClickHandler = useRef(null);

    // Fetch quiz data and images for quiz cards
    const fetchQuizData = async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASEURL}/data`, requestOptions);
            if (!response.ok) {
                throw new Error('Failed to fetch quiz data');
            }
            const data = await response.json();
            setQuestions(data); // Set questions directly from the API response

            // Fetch quiz card images using fetchImages utility function
            const images = await fetchImages('', 'quiz card');
            setQuizCardImages(images);

            setIsLoading(false);
            setCSRF(response.headers.get('X-CSRF-Token'));
        } catch (error) {
            console.error('Error fetching quiz data:', error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchQuizData();
    }, []);

    useEffect(() => {
        if (timer === 0 && !transitioning) {
            setTransitioning(true);

            // If no answer was selected, log a null answer
            if (!clickHandled) {
                logAnswer(null);
            }

            // If it’s the last question, submit answers
            if (currentQuestionIndex === questions.length - 1 && !hasSubmitted) {
                submitAnswers([
                    ...answerLog,
                    { question: questions[currentQuestionIndex].question, selectedAnswer: clickHandled ? null : null },
                ]);
            } else {
                handleNextQuestion();
            }

            setTimeout(() => {
                setTransitioning(false);
                setClickHandled(false); // Reset clickHandled for the next question
            }, 500); // Grace period for transitioning
        } else if (timer > 0) {
            const countdown = setTimeout(() => setTimer((prevTimer) => prevTimer - 1), 1000);
            return () => clearTimeout(countdown);
        }
    }, [timer, transitioning, clickHandled, currentQuestionIndex, questions.length, hasSubmitted, answerLog]);



    const logAnswer = (selectedAnswer) => {
        const currentQuestion = questions[currentQuestionIndex];
        setAnswerLog((prevAnswerLog) => {
            if (!prevAnswerLog.some(log => log.question === currentQuestion.question)) {
                return [
                    ...prevAnswerLog,
                    { question: currentQuestion.question, selectedAnswer }
                ];
            }
            return prevAnswerLog;
        });
    };

    const handleAnswerClick = (answerIndex) => {
        if (transitioning || clickHandled) return; // Ignore clicks during transitions or if already clicked

        setClickHandled(true); // Mark that the click has been handled

        const currentQuestion = questions[currentQuestionIndex];
        const selectedAnswer = currentQuestion.answers[answerIndex];

        logAnswer(selectedAnswer);
        setSelectedAnswerIndex(answerIndex);

        if (currentQuestionIndex === questions.length - 1 && !hasSubmitted) {
            submitAnswers([
                ...answerLog,
                { question: currentQuestion.question, selectedAnswer },
            ]);
        } else {
            handleNextQuestion();
        }
    };
    const handleNextQuestion = () => {
        setTransitioning(true); // Prevent actions during the transition
        setTimeout(() => {
            if (currentQuestionIndex < questions.length - 1) {
                setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
                setSelectedAnswerIndex(null); // Clear selected answer for the next question
                setTimer(10); // Reset the timer
            }
            setTransitioning(false); // Allow actions again
            setClickHandled(false); // Reset click state for the next question
        }, 500); // Grace period for transitioning
    };



    const submitAnswers = async (answers) => {
        setIsSubmitting(true);
        setHasSubmitted(true); // Set the flag to true to indicate submission
        try {
            const response = await fetch(`${process.env.REACT_APP_BASEURL}/checkAnswers`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Csrf-Token': csrfToken,
                },
                body: JSON.stringify({ answers }),
            });

            const resultData = await response.json();
            setIsSubmitting(false);

            // Show the loading circle for 5 seconds before showing the result
            setIsProcessingResult(true);
            setTimeout(() => {
                setResult(resultData);
                setIsProcessingResult(false);
            }, 10000);
        } catch (error) {
            console.error('Error submitting answers:', error);
            setIsSubmitting(false);
        }
    };

    const handlePlayAgain = () => {
        // Reset the quiz state
        setCurrentQuestionIndex(0);
        setSelectedAnswerIndex(null);
        setTimer(10);
        setAnswerLog([]);
        setResult(null);
        setHasSubmitted(false);
        setIsProcessingResult(false);

        // Fetch new quiz data
        setIsLoading(true); // Show loading indicator
        fetchQuizData(); // Fetch new questions
    };

    const handleShowLeaderboard = () => {
        // Redirect to the leaderboard page
        window.location.href = '/leaderboard';
    };

    const handleShowResults = () => {
        // Toggle the results view
        setResult({ ...result, showDetails: !result.showDetails });
    };

    if (isLoading) {
        return (
            <div className="loading-circle-centered">
                <div className="loading-circle-inner"></div>
            </div>
        );
    }

    if (questions.length === 0) {
        return <div>No questions available</div>;
    }

    // Calculate progress
    const progress = ((currentQuestionIndex + 1) / questions.length) * 100;

    return (
        <div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="">
                            {!isSubmitting && !isProcessingResult ? (
                                <>
                                    {!result ? (
                                        <>
                                            <div className="d-none d-md-block mb-4 mt-4"> {/* Hidden on small devices */}
                                                <Timer seconds={timer} />
                                            </div>
                                            <div className="d-block d-md-none mb-2 mt-2"> {/* Shown only on small devices */}
                                                <TimerSmall seconds={timer} />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-none d-md-block mb-4 mt-4 timer-spacer"> {/* Spacer shown when there is a result */}
                                            </div>
                                            <div className="d-block d-md-none mb-2 mt-2 timer-spacer-small"> {/* Spacer shown when there is a result */}
                                            </div>
                                        </>
                                    )}
                                </>
                            ) : (
                                <div className="d-flex justify-content-center align-items-center h-100 mt-4">
                                    <LoadingCircle />
                                </div>
                            )}
                        </div>
                        <div className="phone-frame">
                            <div className="card p-4 h-100">
                                {isSubmitting || isProcessingResult ? (
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                        <AmazonReferral />
                                    </div>
                                ) : result ? (
                                    <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                        <div className="result text-center">
                                            <h3 className="mb-4">{result.punchline}</h3> {/* Display the punchline */}
                                            <FontAwesomeIcon icon={result.score > 5 ? faThumbsUp : faThumbsDown} size="3x" className="mb-4" style={{ color: result.score > 5 ? 'green' : 'red' }} />
                                            <h3 className="mb-4">You scored {result.score} out of 10!</h3>
                                            <div className="mt-4 d-flex justify-content-center" style={{ gap: '10px' }}>
                                                <button className="btn btn-light" onClick={handlePlayAgain}><FontAwesomeIcon icon={faRedo} /> Play Again</button>
                                                <button className="btn btn-light" onClick={handleShowLeaderboard}><FontAwesomeIcon icon={faTrophy} /> Leaderboard</button>
                                                <button className="btn btn-light" onClick={handleShowResults}>
                                                    <FontAwesomeIcon icon={faInfoCircle} /> {result.showDetails ? 'Hide Result' : 'Show Result'}
                                                </button>
                                            </div>
                                            {result.showDetails && (
                                                <div className="mt-4">
                                                    <div className="phone-frame"> {/* Reuse the quiz card styling */}
                                                        <div className="card p-4">
                                                            <ul className="list-group list-group-flush">
                                                                {result.detailedAnswers.map((answer, index) => (
                                                                    <li key={index} className="list-group-item">
                                                                        <h5 className="text-center">{answer.question}</h5> {/* Center the question */}
                                                                        <ul className="list-group list-group-flush">
                                                                            {questions.find(q => q.question === answer.question).answers.map((a, aIndex) => (
                                                                                <li
                                                                                    key={aIndex}
                                                                                    className={`list-group-item d-flex justify-content-between align-items-center ${answer.selectedAnswer === a ? 'font-weight-bold' : ''}`}
                                                                                    style={{
                                                                                        color: a === answer.correctAnswer
                                                                                            ? 'green'
                                                                                            : answer.selectedAnswer === a
                                                                                                ? 'red'
                                                                                                : 'black',
                                                                                        cursor: 'default'
                                                                                    }}
                                                                                >
                                                                                    <div className="d-flex align-items-center">
                                                                                        <img src={quizCardImages[aIndex]?.url || ''} alt={quizCardImages[aIndex]?.alt || 'Quiz Card'} style={{ marginRight: '10px', height: '30px', width: '30px' }} />
                                                                                        <span style={{ textAlign: 'left', flex: 1 }}>{a}</span>
                                                                                    </div>
                                                                                    <div>
                                                                                        {a === answer.correctAnswer && <FontAwesomeIcon icon={faCheckCircle} className="text-success" />}
                                                                                        {answer.selectedAnswer === a && a !== answer.correctAnswer && <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />}
                                                                                    </div>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className="text-center mb-4">
                                            <h3 className="d-none d-md-block">{questions[currentQuestionIndex].question}</h3> {/* Hidden on small devices */}
                                            <h5 className="d-block d-md-none">{questions[currentQuestionIndex].question}</h5> {/* Shown only on small devices */}
                                        </div>
                                        <ul className="list-group list-group-flush">
                                            {questions[currentQuestionIndex].answers.map((answer, index) => (
                                                <li
                                                    key={index}
                                                    onClick={() => handleAnswerClick(index)}
                                                    className={`list-group-item ${selectedAnswerIndex === index ? 'active' : ''} hover-effect`}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <img src={quizCardImages[index]?.url || ''} alt={quizCardImages[index]?.alt || 'Quiz Card'} style={{ marginRight: '10px', height: '30px', width: '30px' }} />
                                                    {answer}
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                )}
                            </div>
                        </div>
                        {/* Progress bar */}
                        {!isSubmitting && !isProcessingResult && !result && (
                            <div className={`progress mt-${window.innerWidth < 768 ? '2' : '4'}`}> {/* Padding 2 for small devices and 4 for medium devices */}
                                <div className="progress-bar" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">
                                    {currentQuestionIndex + 1}/{questions.length}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QuizGame;
