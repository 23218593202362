import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const TermsOfService = () => {
    const navigate = useNavigate();

    const goToLandingPage = () => {
        navigate('/');
    };

    return (
        <div className="privacy-policy-container">
            <h1>Terms of Service for Yakeroo</h1>
            <p><strong>Last Updated:</strong> November 14, 2024</p>

            {/* Button to go back to the landing page */}
            <div className="mt-4">
                <Button variant="btn btn-light btn-md" onClick={goToLandingPage}>
                    Back
                </Button>
            </div>

            <h2>1. Introduction</h2>
            <p>
                Thank you for choosing Yakeroo (https://yakeroo.com.au). These Terms of Service govern your access to and use of our website and services. By accessing or using the Service, you agree to be bound by these terms. If you disagree with any part, please do not access the Service.
            </p>

            <h2>2. Eligibility and Account Registration</h2>
            <p>
                To use certain features of our Service, you may be required to register for an account. You must provide accurate and complete information and keep this information up to date. You are responsible for maintaining the confidentiality of your account information, including your password, and for all activities that occur under your account.
            </p>

            <h2>3. Services</h2>
            <p>
                Yakeroo provides a platform designed to enhance your digital experience. Our services are available to users who register an account and provide the necessary information, including an email address.
            </p>

            <h2>4. Acceptable Use</h2>
            <p>
                You agree to use the Service solely for lawful purposes. Prohibited activities include:
            </p>
            <ul>
                <li>Engaging in fraudulent schemes or illegal activities</li>
                <li>Interfering with the Service or its infrastructure</li>
                <li>Impersonating others or misrepresenting your affiliation</li>
                <li>Violating any of your contractual obligations to Yakeroo</li>
            </ul>

            <h2>5. Data Collection and Privacy</h2>
            <p>
                We collect personal and non-personal information as outlined in our <a href="/privacy-policy">Privacy Policy</a>. By using our Service, you agree to our data collection and handling practices.
            </p>

            <h2>6. Intellectual Property</h2>
            <p>
                All content on Yakeroo, including text, graphics, logos, and software, is owned by Yakeroo or its content suppliers and protected by international copyright and trademark laws.
            </p>

            <h2>7. Account Security</h2>
            <p>
                You are responsible for maintaining the security of your account. Please notify us immediately if you suspect unauthorized access. Yakeroo is not liable for losses from unauthorized use of your account.
            </p>

            <h2>8. Limitation of Liability</h2>
            <p>
                To the extent permitted by law, Yakeroo shall not be liable for any indirect, incidental, special, or consequential damages arising from your use or inability to use the Service.
            </p>

            <h2>9. Australian Consumer Law</h2>
            <p>
                Nothing in these Terms limits your rights under the Australian Consumer Law. Our liability, where it cannot be excluded, is limited to re-performing the services or paying for the re-performance.
            </p>

            <h2>10. Termination</h2>
            <p>
                Yakeroo reserves the right to terminate your account for any reason, including if you breach these Terms. Upon termination, your right to use the Service will cease immediately.
            </p>

            <h2>11. Changes to Terms</h2>
            <p>
                We may modify or replace these Terms at any time. If a change is material, we will provide 30 days' notice. Your continued use of the Service signifies acceptance of any changes.
            </p>

            <h2>12. Governing Law</h2>
            <p>
                These Terms are governed by the laws of New South Wales, Australia. You agree to submit to the jurisdiction of the courts located in New South Wales for any disputes.
            </p>

            <h2>13. Dispute Resolution</h2>
            <p>
                If a dispute arises, you agree to attempt negotiation in good faith. If unresolved, you agree to mediation before proceeding to litigation.
            </p>

            <h2>14. Contact Us</h2>
            <p>
                For questions regarding these Terms, contact us at <a href="mailto:hello@yakeroo.com.au">hello@yakeroo.com.au</a>.
            </p>

            <h2>15. Acknowledgement</h2>
            <p>
                By using our website, you acknowledge that you have read, understood, and agree to these Terms of Service.
            </p>

        </div>
    );
};

export default TermsOfService;

