import React from 'react';
import NavBar from './Components/NavBar';
import QuizGame from './Components/QuizGame';

function QuizApp() {
  return (
    <div>
      {/* Render NavBar and QuizGame directly */}
      <NavBar />
      <QuizGame />
    </div>
  );
}

export default QuizApp;
