import React, { useState, useEffect } from 'react';
import NavBar from './Components/NavBar';
import BannerHeading from './Components/BannerHeading';
import Features from './Components/Features';
import Testimonials from './Components/Testimonials';
import BannerLight from './Components/BannerLight';
import Teams from './Components/Teams';
import Contact from './Components/Contact';
import Footer from './Components/Footer';

function LandingPage() {
  const [isNavBarLoaded, setIsNavBarLoaded] = useState(false); // Track when NavBar is loaded

  // Callback function to set NavBar as loaded
  const handleNavBarLoad = () => {
    setIsNavBarLoaded(true);
  };

  return (
    <div>
      {/* Pass the callback to NavBar */}
      <NavBar onNavBarLoad={handleNavBarLoad} />

      {/* Only render the rest of the content once NavBar is loaded */}
      {isNavBarLoaded ? (
        <>
          <BannerHeading />
          <Features />
          <Testimonials />
          <BannerLight />
          <Teams />
          <Contact />
          <Footer />
        </>
      ) : (
        <div className="loading-circle-centered">
          <div className="loading-circle">
            <div className="loading-circle-inner"></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LandingPage;
