import React, { useState, useEffect } from 'react';
import { fetchImages } from '../utils/fetchImages'; // Import your fetchImages utility

function Section() {
  const [bannerUrl, setBannerUrl] = useState(null);

  useEffect(() => {
    // Fetch the banner image when the component mounts
    const fetchBannerImage = async () => {
      try {
        const images = await fetchImages('', 'banner'); // Fetching images by category 'banner'
        if (images && images.length > 0) {
          setBannerUrl(images[0].url); // Assuming the first image is the one to use
        }
      } catch (error) {
        console.error('Error fetching banner image:', error);
      }
    };

    fetchBannerImage();
  }, []); // Empty dependency array ensures this runs once when the component mounts

  return (
    <section className="py-4 py-xl-5">
      <div className="container d-flex flex-column align-items-center">
        <div className="text-black bg-primary border rounded border-0 p-4 p-md-5 w-100 d-flex flex-column align-items-center">
          <h2 className="fw-bold text-white mb-3 text-center">Ready to Test Your Aussie Slang Skills?</h2>
          {bannerUrl ? (
            <img src={bannerUrl} alt="Banner" className="mb-4 banner-image" />
          ) : (
            <p>Loading banner...</p>
          )}
          <div className="my-3">
            <a className="btn btn-light btn-lg glow-button" role="button" href={`${process.env.REACT_APP_BASEURL}/quiz`}>
              Get cracking!
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section;
