import React, { useState, useEffect } from 'react';
import Navbar from './Components/NavBar';
import SettingsPage from './Components/SettingsPage';
import axios from 'axios';

const Settings = () => {
    const [isNavBarLoaded, setIsNavBarLoaded] = useState(false); // Track NavBar loading
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        confirmPassword: '',
        profilePicture: '',
    });
    const [rsaPublicKey, setRsaPublicKey] = useState(null);
    const [csrfToken, setCsrfToken] = useState(null);

    // Callback function to set NavBar as loaded
    const handleNavBarLoad = () => {
        setIsNavBarLoaded(true);
    };

    // Fetch RSA public key and CSRF token once NavBar is loaded
    useEffect(() => {
        if (isNavBarLoaded) {
            const fetchPublicKeyAndCsrfToken = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BASEURL}/public-key`);
                    setRsaPublicKey(response.data);
                    setCsrfToken(response.headers['x-csrf-token']);
                } catch (error) {
                    console.error('Error fetching RSA public key and CSRF token:', error);
                }
            };
            fetchPublicKeyAndCsrfToken();
        }
    }, [isNavBarLoaded]);

    return (
        <div>
            {/* Pass the callback to NavBar */}
            <Navbar onNavBarLoad={handleNavBarLoad} />

            {/* Only render the SettingsPage after NavBar is loaded */}
            {isNavBarLoaded ? (
                <SettingsPage
                    rsaPublicKey={rsaPublicKey}
                    csrfToken={csrfToken}
                    formData={formData}
                    setFormData={setFormData}
                />
            ) : (
                <div className="loading-circle-centered">
                    <div className="loading-circle">
                        <div className="loading-circle-inner"></div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Settings;
