import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const CookiePolicyPopup = () => {
    const [showPopup, setShowPopup] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const acceptedCookies = localStorage.getItem('acceptedCookies');
        if (acceptedCookies !== 'true') {
            setShowPopup(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('acceptedCookies', 'true');
        setShowPopup(false);
    };

    const handleLearnMore = () => {
        navigate('/cookiepolicy');
    };

    if (!showPopup) return null; // Don't render anything if cookies are accepted

    return (
        <div className="cookie-overlay">
            <div className="cookie-content">
                <h2>Cookie Policy</h2>
                <p>
                    We use cookies to enhance your experience. By continuing to visit this site, you agree to our use of cookies.
                </p>
                <div className="cookie-buttons">
                    <button className="accept-button" onClick={handleAccept}>
                        Accept
                    </button>
                    <button className="learn-more-button" onClick={handleLearnMore}>
                        Learn More
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CookiePolicyPopup;
