import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import forge from 'node-forge';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchImages } from '../utils/fetchImages';
import './Authpage.css';

const AuthPage = () => {
    const [isLogin, setIsLogin] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [selectedProfilePicture, setSelectedProfilePicture] = useState('');
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
    });
    const [message, setMessage] = useState('');
    const [emailConfirmationOutstanding, setEmailConfirmationOutstanding] = useState(false);
    const [rsaPublicKey, setRsaPublicKey] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [turnstileToken, setTurnstileToken] = useState(null); // For Turnstile verification
    const [profilePictures, setProfilePictures] = useState([]);
    const turnstileRef = useRef(null);
    const [csrfToken, setCSRF] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [termsAccepted, setTermsAccepted] = useState(false);

    useEffect(() => {
        // Check if the page has already been reloaded
        const hasReloaded = sessionStorage.getItem('AuthReloaded');

        if (location.pathname === '/auth' && !hasReloaded) {
            sessionStorage.setItem('AuthReloaded', 'true'); // Set the reload flag
            window.location.reload(); // Reload the page on the /auth route
        }
    }, [location.pathname]); // Trigger reload on route change


    useEffect(() => {
        // Fetch RSA public key and initialize Turnstile on load
        fetchRSAPublicKey();

        // Fetch profile pictures only once when the component mounts
        const fetchProfilePictures = async () => {
            try {
                const images = await fetchImages('', 'userProfile'); // Fetch with category 'userProfile'
                setProfilePictures(images);
            } catch (error) {
                console.error('Error fetching profile pictures:', error);
            }
        };

        fetchProfilePictures();

        // Initialize Turnstile only if not already loaded
        if (!window.turnstileScriptLoaded) {
            const script = document.createElement('script');
            script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
            window.turnstileScriptLoaded = true; // Mark script as loaded
        }

        window.handleTurnstileVerify = (token) => {
            setTurnstileToken(token);
        };

        // Adjust Turnstile size based on screen width
        const adjustTurnstileSize = () => {
            const turnstileElement = document.querySelector('.cf-turnstile');
            if (turnstileElement) {
                const screenWidth = window.innerWidth;
                turnstileElement.setAttribute('data-size', screenWidth < 400 ? 'compact' : 'normal');
            }
        };

        // Call the function on initial load and window resize
        adjustTurnstileSize();
        window.addEventListener('resize', adjustTurnstileSize);

        return () => {
            // Cleanup on unmount
            window.removeEventListener('resize', adjustTurnstileSize);
            if (window.turnstile) {
                window.turnstile.reset(); // Reset Turnstile widget
            }
        };
    }, []);

    const fetchRSAPublicKey = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASEURL}/public-key`);
            setRsaPublicKey(response.data);
            setCSRF(response.headers['x-csrf-token']);
        } catch (error) {
            console.error('Error fetching RSA public key:', error);
        }
    };

    const toggleAuthMode = () => {
        setIsLogin(!isLogin);
        setMessage('');
        setEmailConfirmationOutstanding(false);
    };

    const handleProfilePictureSelect = (pictureName) => {
        setSelectedProfilePicture(pictureName);
        setShowModal(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const resetTurnstile = () => {
        if (window.turnstile && window.turnstile.reset) {
            window.turnstile.reset(); // Reset Turnstile widget
        }
    };

    const removeTurnstileScript = () => {
        const scriptElement = document.querySelector('script[src="https://challenges.cloudflare.com/turnstile/v0/api.js"]');
        if (scriptElement) {
            scriptElement.remove(); // Remove Turnstile script from DOM
            window.turnstileScriptLoaded = false; // Reset the script load state
        }
    };

    const handleTermsAcceptance = (e) => {
        setTermsAccepted(e.target.checked);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (!isLogin && !termsAccepted) {
            setMessage('Please accept the Privacy Policy and Terms & Conditions.');
            return;
        }

        const { username, email, password, confirmPassword } = formData;
        const passwordValidation = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{12,}$/);

        if (!isLogin && !passwordValidation.test(password)) {
            setMessage('Password must be at least 12 characters long and include upper and lower case letters, and special characters.');
            setIsLoading(false);
            return;
        }

        if (!isLogin && password !== confirmPassword) {
            setMessage('Passwords do not match');
            setIsLoading(false);
            return;
        }

        if (!isLogin && !selectedProfilePicture) {
            setMessage('Profile picture is required');
            setIsLoading(false);
            return;
        }

        if (!turnstileToken) {
            setMessage('Please complete the Turnstile verification');
            setIsLoading(false);
            return;
        }

        let encryptedPassword = password;
        if (rsaPublicKey) {
            const publicKey = forge.pki.publicKeyFromPem(rsaPublicKey);
            encryptedPassword = forge.util.encode64(publicKey.encrypt(password));
        }

        const payload = {
            email,
            password: encryptedPassword,
            turnstileToken,
            ...(isLogin ? {} : { username, profilePicture: selectedProfilePicture }),
        };

        const url = isLogin ? `${process.env.REACT_APP_BASEURL}/login` : `${process.env.REACT_APP_BASEURL}/register`;

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Csrf-Token': csrfToken,
                },
                body: JSON.stringify(payload),
            });

            const data = await response.json();

            if (response.ok) {
                if (isLogin) {

                    // Redirect to quiz page on successful login
                    setMessage('Login successful');
                    resetTurnstile();
                    removeTurnstileScript();

                    window.location.href = '/quiz';

                } else {
                    // Toggle back to login after successful registration
                    setMessage('Registration successful. Please log in.');
                    setIsLogin(true); // Switch to login form
                    setFormData({
                        username: '',
                        email: '',
                        password: '',
                        confirmPassword: '',
                    });
                    setSelectedProfilePicture('');
                }
            } else {
                if (data.message === 'Email confirmation outstanding') {
                    setEmailConfirmationOutstanding(true);
                    setMessage('Email confirmation outstanding. Please check your email for the confirmation link.');
                } else {
                    setMessage(data.message || 'An error occurred');
                }
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('An error occurred');
        } finally {
            setIsLoading(false);
            setTurnstileToken(null);
            resetTurnstile(); // Reset Turnstile after form submission
        }
    };

    const handleResendConfirmationEmail = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASEURL}/emailconfirmation`,
                { email: formData.email },
                { headers: { 'X-Csrf-Token': csrfToken }, withCredentials: true }
            );
            if (response.data.success) {
                setMessage('Confirmation email resent successfully. Please check your email.');
            } else {
                setMessage('Error resending confirmation email. Please try again later.');
            }
        } catch (error) {
            console.error('Error resending confirmation email:', error);
            setMessage('Error resending confirmation email. Please try again later.');
        } finally {
            setIsLoading(false);
            resetTurnstile(); // Reset Turnstile after resending confirmation email
        }
    };

    const handlePasswordReset = async () => {
        if (!turnstileToken) {
            setMessage('Please complete the Turnstile verification');
            return;
        }
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASEURL}/password-reset`,
                { email: formData.email },
                { headers: { 'X-Csrf-Token': csrfToken }, withCredentials: true }
            );
            if (response.data.success) {
                setMessage('Password reset link sent successfully. Please check your email.');
            } else {
                setMessage('Error sending password reset link. Please try again later.');
            }
        } catch (error) {
            console.error('Error sending password reset link:', error);
            setMessage('Error sending password reset link. Please try again later.');
        } finally {
            setIsLoading(false);
            resetTurnstile(); // Reset Turnstile after password reset
        }
    };

    return (
        <section className="position-relative py-4 py-xl-5">
            <div className="container">
                <div className="row mb-5">
                    <div className="col-md-8 col-xl-6 text-center mx-auto">
                        <h2>{isLogin ? 'Welcome Back!' : 'Create an Account'}</h2>
                        <p className="w-lg-50">
                            {isLogin
                                ? 'Please log in below to access your account and track your quiz scores. If you don\'t have an account, you can create one in just a few steps.'
                                : 'Register below to create your account and start tracking your quiz scores.'}
                        </p>
                        <button className="btn btn-light mt-3" onClick={toggleAuthMode} disabled={isLoading}>
                            {isLogin ? 'Need to register? Sign up here' : 'Already have an account? Log in here'}
                        </button>
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-6 col-xl-4">
                        <div className="card mb-5">
                            <div className="card-body d-flex flex-column align-items-center">
                                <div className="bs-icon-xl bs-icon-circle bs-icon-primary bs-icon my-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-person">
                                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664z"></path>
                                    </svg>
                                </div>
                                <form className="text-center" onSubmit={handleSubmit}>
                                    {!isLogin && (
                                        <div className="mb-3">
                                            <input className="form-control" type="text" name="username" placeholder="Username" value={formData.username} onChange={handleChange} />
                                        </div>
                                    )}
                                    <div className="mb-3">
                                        <input className="form-control" type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} />
                                    </div>
                                    <div className="mb-3">
                                        <input className="form-control" type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} />
                                    </div>
                                    {!isLogin && (
                                        <>
                                            <div className="mb-3">
                                                <input className="form-control" type="password" name="confirmPassword" placeholder="Confirm Password" value={formData.confirmPassword} onChange={handleChange} />
                                            </div>
                                            <div className="mb-3">
                                                <button type="button" className="btn btn-light d-block w-100" onClick={() => setShowModal(true)} disabled={isLoading}>Select Profile Picture</button>
                                            </div>
                                            {selectedProfilePicture && (
                                                <div className="mb-3">
                                                    <img src={profilePictures.find(p => p.name === selectedProfilePicture)?.url} alt="Profile" className="img-fluid img-thumbnail m-2" style={{ width: '100px', height: '100px' }} />
                                                </div>
                                            )}
                                        </>
                                    )}
                                    {!isLogin && (
                                        <div className="mb-3 form-check" style={{ maxWidth: '320px', margin: '0 auto', textAlign: 'center' }}>
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="termsAccepted"
                                                onChange={handleTermsAcceptance}
                                            />
                                            <label className="form-check-label" htmlFor="termsAccepted">
                                                I agree to the <a href={`${process.env.REACT_APP_BASEURL}/privacyPolicy`} target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href={`${process.env.REACT_APP_BASEURL}/termsAndConditions`} target="_blank" rel="noopener noreferrer">Terms and Conditions</a>.
                                            </label>
                                        </div>
                                    )}

                                    <div ref={turnstileRef} className="cf-turnstile custom-turnstile mb-3" data-sitekey={process.env.REACT_APP_TURNSTILE_SITEKEY} data-callback="handleTurnstileVerify"></div>
                                    <div className="mb-3">
                                        <button className="btn btn-light d-block w-100" type="submit" disabled={isLoading || !turnstileToken || (!isLogin && !termsAccepted)}>{isLogin ? 'Login' : 'Register'}</button>
                                    </div>
                                    <div className="mb-3">
                                        {message && <div className="message-box bs-body-color">{message}</div>}
                                    </div>
                                    {emailConfirmationOutstanding && (
                                        <button className="btn btn-light mt-2" onClick={handleResendConfirmationEmail} disabled={isLoading}>
                                            Resend Confirmation Email
                                        </button>
                                    )}
                                    {isLogin && (
                                        <p className="text-muted forgot-password" style={{ cursor: 'pointer', color: turnstileToken ? 'inherit' : 'gray' }} onClick={handlePasswordReset}>
                                            Forgot your password?
                                        </p>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal for profile picture selection */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Profile Picture</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-wrap justify-content-center modal-body">
                        {profilePictures.map((picture, index) => (
                            <img
                                key={index}
                                src={picture.url}
                                alt={`Profile ${index + 1}`}
                                className="img-thumbnail m-2"
                                style={{ width: '100px', height: '100px', cursor: 'pointer' }}
                                onClick={() => handleProfilePictureSelect(picture.name)}
                            />
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)} disabled={isLoading}>Close</Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
};

export default AuthPage;
