import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faHandPeace } from '@fortawesome/free-solid-svg-icons';
import { fetchImages } from '../utils/fetchImages'; // Adjust the path as needed
import './Teams.css'; // Custom CSS file for styling

function Teams() {
    const [imageSrc, setImageSrc] = useState(''); // State to store the image URL

    // Fetch the image with category 'developer' when the component mounts
    useEffect(() => {
        const loadImage = async () => {
            try {
                const images = await fetchImages('', 'developer');
                if (images && images.length > 0) {
                    setImageSrc(images[0].url); // Assuming the first image is what you want
                }
            } catch (error) {
                console.error('Error loading image:', error);
            }
        };
        loadImage();
    }, []);

    return (
        <div className="container py-4 py-xl-5">
            <div className="row mb-5 justify-content-center">
                <div className="row">
                    <div className="col-md-8 col-xl-6 text-center mx-auto mb-3">
                        <h2>Developer</h2>
                    </div>
                </div>
                <div className="col-md-8 col-xl-6 text-center mx-auto">
                    <div className="card developer-card shadow-lg">
                        <div className="card-body d-flex flex-column align-items-center">
                            <img
                                className="rounded-circle mb-4 developer-image"
                                width="130"
                                height="130"
                                src={imageSrc || 'https://cdn.bootstrapstudio.io/placeholders/1400x800.png'}
                                alt="Lukas"
                            />
                            <ul className="list-inline fs-6 text-muted mb-3">
                                <li className="list-inline-item text-center">
                                    <a
                                        href="https://www.linkedin.com/in/lukas-boettcher-713812109"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="linkedin-icon"
                                    >
                                        <FontAwesomeIcon icon={faLinkedin} size="2x" />
                                    </a>
                                </li>
                            </ul>
                            <p className="mx-auto">
                                Hi!
                                <br />
                                I'm Lukas, the creator of <strong>Yakeroo</strong>, the Aussie quiz app.
                                I’ve always enjoyed the quirks of Australian life and created Yakeroo to share its unique expressions and humor with others.
                                It's free, easy to use, and designed to keep you entertained while learning.
                                <br />
                                <FontAwesomeIcon icon={faHandPeace} size="lg" className="icon" />
                                <br />
                                Enjoy the quiz!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Teams;
