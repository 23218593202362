import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCog, faQuestionCircle, faChartLine, faSignOutAlt, faShoppingCart, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import CookiePolicyPopup from './CookiePolicyPopup';
import { fetchImages } from '../utils/fetchImages'; // Import the fetchImages function

function Navbar({ onNavBarLoad }) {  // Accept the callback function as a prop
  const location = useLocation();
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
  const [logo, setLogo] = useState(null); // State to store the logo image
  const [isLoaded, setIsLoaded] = useState(false); // State to track if all requests have finished

  const removeReloadFlag = () => {
    sessionStorage.removeItem('AuthReloaded');
  };
  removeReloadFlag();

  // Effect to check if previous page was /auth and reload the page
  useEffect(() => {
    const previousUrl = document.referrer; // Get the referring URL
    const hasReloaded = sessionStorage.getItem('NavReloaded'); // Check if the page has already been reloaded

    if (previousUrl && previousUrl.includes('/auth') && !hasReloaded) {
      sessionStorage.setItem('NavReloaded', 'true'); // Set a flag in sessionStorage
      window.location.reload(); // Reload the page
    }
  }, []);

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASEURL}/authstatus`, {
          withCredentials: true,
        });
        setLoggedIn(response.data.message === 'Authorized');
      } catch (error) {
        setLoggedIn(false);
      }
    };

    const fetchLogo = async () => {
      try {
        const logoImage = await fetchImages('', 'navbar');
        if (logoImage.length > 0) {
          setLogo(logoImage[0].url); // Set the first image URL as the logo
        }
      } catch (error) {
        console.error('Error fetching navbar logo:', error);
      }
    };

    // Fetch both the login status and logo
    const fetchData = async () => {
      await Promise.all([checkLoginStatus(), fetchLogo()]);
      setIsLoaded(true); // Set to true when both requests have finished
      if (onNavBarLoad) onNavBarLoad(); // Call the onNavBarLoad function once loading is complete
    };

    fetchData();
  }, [onNavBarLoad]);

  const handleLogout = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_BASEURL}/logout`, {}, { withCredentials: true });
      setLoggedIn(false);
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  // Only render the Navbar when all data is loaded
  if (!isLoaded) {
    return null; // Optionally, show a loading spinner here
  }

  return (
    <nav className="navbar navbar-expand-lg bg-primary py-3">
      <div className="container">
        <a className="navbar-brand d-flex align-items-center" href="/">
          <span className="bs-icon-sm bs-icon-rounded bs-icon-primary d-flex justify-content-center align-items-center me-2 bs-icon">
            {/* Use the fetched logo */}
            {logo && <img src={logo} alt="yakeroo" className="ml-sm logo" />}
          </span>
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navcol-4">
          <span className="visually-hidden">Toggle navigation</span>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navcol-4">
          <ul className="navbar-nav me-auto">
            {(location.pathname === '/' || location.pathname === '/home') && (
              <>
                <li className="nav-item">
                  <Link className="nav-link nav-link-hover" to="/quiz">
                    <FontAwesomeIcon icon={faQuestionCircle} className="me-2" />Quiz
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link nav-link-hover" to="/leaderboard">
                    <FontAwesomeIcon icon={faChartLine} className="me-2" />Leaderboard
                  </Link>
                </li>
                {loggedIn && location.pathname !== '/settings' && (
                  <li className="nav-item">
                    <Link className="nav-link nav-link-hover" to="/settings">
                      <FontAwesomeIcon icon={faCog} className="me-2" />Settings
                    </Link>
                  </li>
                )}
                {/* <li className="nav-item">
                  <Link className="nav-link nav-link-hover" to="/shop">
                    <FontAwesomeIcon icon={faShoppingCart} className="me-2" />Shop
                  </Link>
                </li> */}
              </>
            )}
            {location.pathname === '/quiz' && (
              <>
                <li className="nav-item">
                  <Link className="nav-link nav-link-hover" to="/">
                    <FontAwesomeIcon icon={faHome} className="me-2" />Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link nav-link-hover" to="/leaderboard">
                    <FontAwesomeIcon icon={faChartLine} className="me-2" />Leaderboard
                  </Link>
                </li>
                {loggedIn && location.pathname !== '/settings' && (
                  <li className="nav-item">
                    <Link className="nav-link nav-link-hover" to="/settings">
                      <FontAwesomeIcon icon={faCog} className="me-2" />Settings
                    </Link>
                  </li>
                )}
                {/* <li className="nav-item">
                  <Link className="nav-link nav-link-hover" to="/shop">
                    <FontAwesomeIcon icon={faShoppingCart} className="me-2" />Shop
                  </Link>
                </li> */}
              </>
            )}
            {location.pathname === '/settings' && (
              <>
                <li className="nav-item">
                  <Link className="nav-link nav-link-hover" to="/">
                    <FontAwesomeIcon icon={faHome} className="me-2" />Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link nav-link-hover" to="/quiz">
                    <FontAwesomeIcon icon={faQuestionCircle} className="me-2" />Quiz
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link nav-link-hover" to="/leaderboard">
                    <FontAwesomeIcon icon={faChartLine} className="me-2" />Leaderboard
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link className="nav-link nav-link-hover" to="/shop">
                    <FontAwesomeIcon icon={faShoppingCart} className="me-2" />Shop
                  </Link>
                </li> */}
              </>
            )}
            {location.pathname === '/leaderboard' && (
              <>
                <li className="nav-item">
                  <Link className="nav-link nav-link-hover" to="/">
                    <FontAwesomeIcon icon={faHome} className="me-2" />Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link nav-link-hover" to="/quiz">
                    <FontAwesomeIcon icon={faQuestionCircle} className="me-2" />Quiz
                  </Link>
                </li>
                {loggedIn && location.pathname !== '/settings' && (
                  <li className="nav-item">
                    <Link className="nav-link nav-link-hover" to="/settings">
                      <FontAwesomeIcon icon={faCog} className="me-2" />Settings
                    </Link>
                  </li>
                )}
                {/* <li className="nav-item">
                  <Link className="nav-link nav-link-hover" to="/shop">
                    <FontAwesomeIcon icon={faShoppingCart} className="me-2" />Shop
                  </Link>
                </li> */}
              </>
            )}
          </ul>
          <ul className="navbar-nav ms-auto">
            {loggedIn ? (
              <li className="nav-item">
                <Link className="nav-link nav-link-hover" role="button" onClick={handleLogout}>
                  <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />Logout
                </Link>
              </li>
            ) : (
              <li className="nav-item">
                <Link className="nav-link nav-link-hover" role="button" to="/auth">
                  <FontAwesomeIcon icon={faSignInAlt} className="me-2" />Login
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
      <CookiePolicyPopup />
    </nav>
  );
}

export default Navbar;

