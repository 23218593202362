import React, { useState, useEffect } from 'react';
import { fetchImages } from '../utils/fetchImages'; // Import the fetch function

function Features() {
  const [currentImage, setCurrentImage] = useState(null);
  const [images, setImages] = useState([]);

  useEffect(() => {
    // Fetch the images with the category 'phone'
    const fetchPhoneImages = async () => {
      try {
        const fetchedImages = await fetchImages('', 'phone'); // Fetch images in the 'phone' category
        setImages(fetchedImages); // Store fetched images
        if (fetchedImages.length > 0) {
          setCurrentImage(fetchedImages[0].url); // Set the first image as default
        }
      } catch (error) {
        console.error('Error fetching phone images:', error);
      }
    };

    fetchPhoneImages();
  }, []);

  useEffect(() => {
    if (images.length === 0) return;

    let currentIndex = 0;
    const imageSwitcher = setInterval(() => {
      currentIndex = (currentIndex + 1) % images.length; // Cycle through images
      setCurrentImage(images[currentIndex].url);
    }, 2000);

    // Cleanup interval on component unmount
    return () => clearInterval(imageSwitcher);
  }, [images]);

  return (
    <div className="container py-xl-5">
      <div className="row row-cols-1 row-cols-md-2">
        {/* Left Column for the Phone */}
        <div className="col d-flex justify-content-center align-items-center">
          <div
            className="rounded-5 bg-light d-flex justify-content-center align-items-center overflow-hidden"
            style={{ maxWidth: '20rem', border: '5px solid #000' }}
          >
            {currentImage ? (
              <img
                alt="Phone"
                src={currentImage}
                style={{ objectFit: 'contain', maxHeight: '100%', maxWidth: '100%' }}
              />
            ) : (
              <p>Loading...</p>
            )}
          </div>
        </div>

        {/* Right Column for the Features */}
        <div className="col d-flex flex-column justify-content-center p-4">
          {/* First feature */}
          <div className="text-center text-md-start d-flex flex-column align-items-center align-items-md-start mb-5">
            <div className="bs-icon-md bs-icon-rounded bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-3 bs-icon md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                fill="currentColor"
                viewBox="0 0 16 16"
                className="bi bi-bell"
              >
                <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6"></path>
              </svg>
            </div>
            <div>
              <h4>Discover Aussie Slang</h4>
              <p>
                Dive into the colorful world of Australian slang with thousands of unique and entertaining questions. Whether you're a local looking to test your knowledge or a visitor wanting to understand the lingo, the app offers an extensive collection of phrases and expressions to explore.
              </p>
              <a className="link-light" href={`${process.env.REACT_APP_BASEURL}/quiz`}>
                Discover&nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                  className="bi bi-arrow-right"
                >
                  <path
                    fillRule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                  ></path>
                </svg>
              </a>
            </div>
          </div>

          {/* Second feature */}
          <div className="text-center text-md-start d-flex flex-column align-items-center align-items-md-start mb-5">
            <div className="bs-icon-md bs-icon-rounded bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-3 bs-icon md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                fill="currentColor"
                viewBox="0 0 16 16"
                className="bi bi-bell"
              >
                <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6"></path>
              </svg>
            </div>
            <div>
              <h4>Free and Easy to Use</h4>
              <p>
                The app is completely free to use, offering unlimited access to all its features without any hidden costs or subscriptions. With a user-friendly interface, you can effortlessly navigate through the questions and enjoy seamless gameplay. No barriers, no hassle—just pure fun and learning at your fingertips.
              </p>
              <a className="link-light" href={`${process.env.REACT_APP_BASEURL}/quiz`}>
                Discover&nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                  className="bi bi-arrow-right"
                >
                  <path
                    fillRule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                  ></path>
                </svg>
              </a>
            </div>
          </div>

          {/* Third feature */}
          <div className="text-center text-md-start d-flex flex-column align-items-center align-items-md-start">
            <div className="bs-icon-md bs-icon-rounded bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-3 bs-icon md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                fill="currentColor"
                viewBox="0 0 16 16"
                className="bi bi-bell"
              >
                <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6"></path>
              </svg>
            </div>
            <div>
              <h4>Competitive Scoring Board</h4>
              <p>
                Challenge your friends and climb to the top of the leaderboard with our competitive scoring system. Track your progress, see how you stack up against others, and earn bragging rights as the ultimate Aussie slang master.
              </p>
              <a className="link-light" href={`${process.env.REACT_APP_BASEURL}/quiz`}>
                Discover&nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                  className="bi bi-arrow-right"
                >
                  <path
                    fillRule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
