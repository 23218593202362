import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const PrivacyPolicy = () => {
    const navigate = useNavigate();

    const goToLandingPage = () => {
        navigate('/');
    };

    return (
        <div className="privacy-policy-container">
            <h1>Privacy Policy</h1>
            <p><strong>Last updated:</strong> November 14, 2024</p>

            {/* Button to go back to the landing page */}
            <div className="mt-4 mb-4">
                <Button variant="btn btn-light btn-md" onClick={goToLandingPage}>
                    Back
                </Button>
            </div>

            <p>
                This Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your information when You use the Service. It informs You of Your privacy rights and how the law protects You.
            </p>
            <p>
                We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
            </p>

            <h2>Interpretation and Definitions</h2>
            <h3>Interpretation</h3>
            <p>The words with capitalized initials have meanings defined under the following conditions.</p>

            <h3>Definitions</h3>
            <ul>
                <li><strong>Account:</strong> A unique account created for You to access our Service.</li>
                <li><strong>Company:</strong> Refers to Yakeroo (referred to as "We", "Us", or "Our").</li>
                <li><strong>Cookies:</strong> Small files placed on Your Device to track activity.</li>
                <li><strong>Country:</strong> New South Wales, Australia.</li>
                <li><strong>Device:</strong> Any device that can access the Service, like a computer or mobile phone.</li>
                <li><strong>Personal Data:</strong> Any information that relates to an identifiable individual.</li>
                <li><strong>Service:</strong> Refers to the Yakeroo website.</li>
                <li><strong>Service Provider:</strong> Any person or entity processing data on behalf of the Company.</li>
                <li><strong>Usage Data:</strong> Data collected automatically when using the Service.</li>
                <li><strong>You:</strong> The individual using the Service or the legal entity represented by the individual.</li>
            </ul>

            <h2>Collecting and Using Your Personal Data</h2>
            <h3>Types of Data Collected</h3>
            <h4>Personal Data</h4>
            <p>We may ask You to provide certain personally identifiable information, such as:</p>
            <ul>
                <li>Email address</li>
                <li>Usage Data</li>
            </ul>

            <h4>Usage Data</h4>
            <p>Usage Data may include IP address, browser type, the pages visited on our Service, and other diagnostic data.</p>

            <h3>Tracking Technologies and Cookies</h3>
            <p>We use Cookies and other tracking technologies to monitor activity and store certain information.</p>
            <ul>
                <li><strong>Browser Cookies:</strong> Small files placed on Your Device to track activity. You can adjust your browser to refuse all Cookies or alert You when a Cookie is sent.</li>
                <li><strong>Web Beacons:</strong> Electronic files in emails or web pages used for tracking purposes.</li>
            </ul>

            <h3>Purpose of Collecting Data</h3>
            <p>We use Your Personal Data to:</p>
            <ul>
                <li>Provide and maintain our Service</li>
                <li>Manage Your Account and registration as a user</li>
                <li>Fulfill and manage orders, contracts, and services</li>
                <li>Communicate updates, notifications, or service information</li>
                <li>Offer news, special offers, and information on related services</li>
                <li>Address and manage Your requests</li>
                <li>Analyze trends, improve functionality, and develop the Service</li>
            </ul>

            <h2>User Rights and Access</h2>
            <p>You have the right to access, update, and correct any Personal Data we hold about You. To request access or make changes, please contact us directly at <a href="mailto:hello@yakeroo.com.au">hello@yakeroo.com.au</a>.</p>

            <h2>Retention of Your Personal Data</h2>
            <p>We will retain Your Personal Data as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. Usage Data will be retained for analysis or as needed to improve Service security.</p>

            <h2>Transfer of Your Personal Data</h2>
            <p>Your information may be transferred to locations outside of Your jurisdiction. We ensure all reasonable steps to protect Your data, including security measures for international transfers.</p>

            <h2>Data Sharing and Disclosure</h2>
            <p>We may share Your Personal Data:</p>
            <ul>
                <li>With Service Providers to analyze Service use</li>
                <li>In business transactions, such as mergers or acquisitions</li>
                <li>With affiliates and business partners</li>
                <li>To comply with legal obligations or prevent unlawful activity</li>
                <li>With Your consent for other purposes</li>
            </ul>

            <h2>Complaints and Dispute Resolution</h2>
            <p>If You believe we have breached the Australian Privacy Principles or have any concerns regarding this Privacy Policy, please contact us at <a href="mailto:hello@yakeroo.com.au">hello@yakeroo.com.au</a>. We will address your concerns in line with our complaint resolution process.</p>

            <h2>Security of Your Personal Data</h2>
            <p>We implement industry-standard security measures to protect Your Personal Data. However, no internet transmission or electronic storage is completely secure.</p>

            <h2>Children's Privacy</h2>
            <p>Our Service is not intended for individuals under the age of 13. If we learn that Personal Data of a child under 13 has been collected, we will delete it.</p>

            <h2>Links to Other Websites</h2>
            <p>Our Service may contain links to third-party sites. We advise reviewing the Privacy Policy of each site you visit, as we are not responsible for their practices.</p>

            <h2>Changes to this Privacy Policy</h2>
            <p>We may update this Privacy Policy periodically. Changes are effective upon posting on this page, with the "Last updated" date updated.</p>

            <h2>Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:hello@yakeroo.com.au">hello@yakeroo.com.au</a>.</p>


        </div>
    );
};

export default PrivacyPolicy;
