import React, { useState, useEffect } from 'react';
import { fetchImages } from '../utils/fetchImages'; // Assuming fetchImages is in the utils folder

function Testimonials() {
    const [testimonialImages, setTestimonialImages] = useState([]);

    useEffect(() => {
        const fetchTestimonialImages = async () => {
            try {
                // Fetch all images with the category 'testimonial'
                const images = await fetchImages('', 'testimonial');
                setTestimonialImages(images);
            } catch (error) {
                console.error('Failed to load testimonial images', error);
            }
        };

        fetchTestimonialImages();
    }, []);

    // Function to map the image to the appropriate testimonial
    const getImageForTestimonial = (testimonialNumber) => {
        if (testimonialImages.length > 0) {
            // Find the image corresponding to the testimonial number (e.g., "1", "2", "3")
            const testimonialImage = testimonialImages.find(image => image.name.includes(testimonialNumber.toString()));
            return testimonialImage ? testimonialImage.url : 'https://cdn.bootstrapstudio.io/placeholders/1400x800.png';
        }
        // Return a placeholder if no images are found
        return 'https://cdn.bootstrapstudio.io/placeholders/1400x800.png';
    };

    return (
        <div className="container py-4 py-xl-5">
            <div className="row mb-3">
                <div className="col-md-8 col-xl-6 text-center mx-auto">
                    <h2>Testimonials</h2>
                </div>
            </div>
            <div className="row gy-4 row-cols-1" /*row-cols-sm-2 row-cols-lg-3"*/>
                <div className="col">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <p className="text-center">
                            "I never realized how much fun learning Aussie slang could be! This app is a fantastic way to get to know the local lingo. The quizzes are both entertaining and educational. Highly recommended for anyone visiting Australia or just wanting to have a good laugh with friends!"
                        </p>
                        <div className="d-flex flex-column align-items-center">
                            <img
                                className="rounded-circle fit-cover mb-2" // Removed flex-shrink-0 and me-3 to center it and added mb-2 for margin-bottom
                                width="100" // Increased width
                                height="100" // Increased height
                                src={getImageForTestimonial(1)}
                                alt="Testimonial 1"
                            />
                            <p className="fw-bold text-primary mb-0">Tash</p> {/* Moved the name directly under the image */}
                        </div>
                        <div className="card border-0 shadow-none">
                            <div className="card-body d-flex align-items-center p-0"></div>
                        </div>
                    </div>
                </div>
                {/*<div className="col">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <p className="text-center">
                            "As an Aussie, I thought I knew all the slang, but this app proved me wrong! It's a great challenge and really well put together. The leaderboard adds a competitive edge, and it's free to use. Great job, Lukas!"</p>
                        <div className="d-flex">
                            <img className="rounded-circle flex-shrink-0 me-3 fit-cover" 
                                width="50" 
                                height="50" 
                                src={getImageForTestimonial(2)} 
                                alt="Testimonial 2" />
                            <div>
                                <p className="fw-bold text-primary mb-0">Jake M.</p>
                            </div>
                        </div>
                        <div className="card border-0 shadow-none">
                            <div className="card-body d-flex align-items-center p-0"></div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <p className="text-center">
                            "This app is an absolute gem! It's user-friendly, free, and packed with thousands of questions that keep you coming back for more. I've learned so much about Aussie slang, and it's been a blast competing with my friends. Can't recommend it enough!"</p>
                        <div className="d-flex">
                            <img className="rounded-circle flex-shrink-0 me-3 fit-cover" 
                                width="50" 
                                height="50" 
                                src={getImageForTestimonial(3)} 
                                alt="Testimonial 3" />
                            <div>
                                <p className="fw-bold text-primary mb-0">Sophia L.</p>
                            </div>
                        </div>
                        <div className="card border-0 shadow-none">
                            <div className="card-body d-flex align-items-center p-0"></div>
                        </div>
                    </div>
                </div>*/}
            </div>
        </div>
    );
}

export default Testimonials;