import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { fetchImages } from '../utils/fetchImages'; // Import the image fetching utility
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTrophy, faMedal } from '@fortawesome/free-solid-svg-icons';
import './Scoreboard.css';

const Scoreboard = () => {
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showPercentage, setShowPercentage] = useState(true);
    const [isLoading, setIsLoading] = useState(true); // Loading state
    const [profilePictures, setProfilePictures] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuthStatus = async () => {
            try {
                const authResponse = await axios.get(`${process.env.REACT_APP_BASEURL}/authstatus`, {
                    withCredentials: true
                });
                if (authResponse.data.message !== 'Authorized') {
                    navigate('/auth'); // Redirect if unauthorized
                } else {
                    fetchData(); // Fetch the scoreboard data if authorized
                }
            } catch (error) {
                console.error('Error checking auth status:', error);
                navigate('/auth'); // Redirect if there's an error
            }
        };

        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASEURL}/score`, {
                    withCredentials: true
                });
                setUsers(response.data);

                // Fetch profile pictures for all users
                const images = await fetchImages('', 'userProfile');
                const profilePicturesMap = images.reduce((acc, image) => {
                    acc[image.name] = image.url;
                    return acc;
                }, {});
                setProfilePictures(profilePicturesMap);
            } catch (error) {
                console.error('Error fetching user data:', error);
            } finally {
                setIsLoading(false); // Set loading to false after fetching data
            }
        };

        checkAuthStatus(); // Call the auth check function on component mount
    }, [navigate]);

    const getProfilePictureUrl = (profilePicture) => {
        return profilePictures[profilePicture]; // No placeholder needed, as all pictures are known
    };

    // Sort users by points or percentage
    const sortedUsers = [...users].sort((a, b) => {
        return showPercentage
            ? b.correctAnswerPercentage - a.correctAnswerPercentage
            : b.totalCorrectAnswers - a.totalCorrectAnswers;
    });

    // Filter remaining users based on search term
    const filteredUsers = sortedUsers.filter(user =>
        user.username.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const getUserRank = (username) => {
        return sortedUsers.findIndex(user => user.username === username) + 1;
    };

    // Determine the tooltip text based on the toggle state
    const infoTooltipText = showPercentage
        ? "This percentage indicates the ratio of correct answers to the total number of questions a user could have answered correctly."
        : "This number represents the total number of questions that a user has answered correctly across all games played.";

    return (
        <div>
            {isLoading ? (
                <div className="loading-circle-centered">
                    <div className="loading-circle">
                        <div className="loading-circle-inner"></div>
                    </div>
                </div>
            ) : (
                <>
                    {/* Show search bar after loading is complete */}
                    <div className="search-bar-container">
                        <input
                            type="text"
                            className="form-control search-input"
                            placeholder="Search users..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <label className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={showPercentage}
                                onChange={() => setShowPercentage(!showPercentage)}
                            />
                            <span className="slider"></span>
                            <span className="toggle-label">Show %</span>
                        </label>
                        <button className="info-button btn btn-primary">
                            <FontAwesomeIcon icon={faInfoCircle} />
                            <span className="tooltip">{infoTooltipText}</span>
                        </button>
                    </div>

                    {/* Show user cards after loading is complete */}
                    <div className="scoreboard-container container mt-5">
                        <div className="list-group">
                            {filteredUsers.map((user) => (
                                <div key={user.username} className="user-card list-group-item">
                                    <div className="card-content">
                                        <img
                                            className="profile-picture"
                                            src={getProfilePictureUrl(user.profilePicture)}
                                            alt={`${user.username}'s profile`}
                                        />
                                        <div className="card-details">
                                            <h5 className="card-title mb-1">{user.username}</h5>
                                            <div className="user-stats">
                                                <div className="user-rank">
                                                    <FontAwesomeIcon icon={faMedal} className="rank-icon" />
                                                    <span className="rank-number">{getUserRank(user.username)}</span>
                                                </div>
                                                {showPercentage ? (
                                                    <div className="user-stat">
                                                        <span className="user-stat-value">
                                                            Percentage: {user.correctAnswerPercentage}%
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <div className="user-stat">
                                                        <span className="user-stat-value">
                                                            Points: {user.totalCorrectAnswers}
                                                        </span>
                                                    </div>
                                                )}
                                                {getUserRank(user.username) <= 3 && (
                                                    <FontAwesomeIcon icon={faTrophy} className={`trophy-icon trophy-${getUserRank(user.username)}`} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Scoreboard;
