import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faKey, faImage, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Form, Modal } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import forge from 'node-forge';
import { fetchImages } from '../utils/fetchImages'; // Fetch images utility

const SettingsPage = ({ rsaPublicKey, csrfToken, formData, setFormData }) => {
    const [usernameMessage, setUsernameMessage] = useState('');
    const [passwordMessage, setPasswordMessage] = useState('');
    const [profilePictureMessage, setProfilePictureMessage] = useState('');
    const [deleteAccountMessage, setDeleteAccountMessage] = useState('');
    const [showProfilePictureModal, setShowProfilePictureModal] = useState(false);
    const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
    const [selectedProfilePicture, setSelectedProfilePicture] = useState('');
    const [profilePictures, setProfilePictures] = useState([]); // New state for profile pictures
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfilePictures = async () => {
            try {
                const images = await fetchImages('', 'userProfile'); // Fetch profile pictures with category userProfile
                setProfilePictures(images);
            } catch (error) {
                console.error('Error fetching profile pictures:', error);
            }
        };

        fetchProfilePictures(); // Call the fetch function on component mount
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (type) => {
        try {
            let payload = {};
            let encryptedPassword = formData.password;

            if (type === 'password' && rsaPublicKey) {
                const publicKey = forge.pki.publicKeyFromPem(rsaPublicKey);
                encryptedPassword = forge.util.encode64(publicKey.encrypt(formData.password));
            }

            switch (type) {
                case 'username':
                    payload = { username: formData.username };
                    break;
                case 'password':
                    if (formData.password !== formData.confirmPassword) {
                        setPasswordMessage('Passwords do not match');
                        return;
                    }
                    payload = { password: encryptedPassword };
                    break;
                case 'profilePicture':
                    payload = { profilePicture: formData.profilePicture };
                    break;
                case 'deleteAccount':
                    break;
                default:
                    return;
            }

            const response = await axios.post(
                `${process.env.REACT_APP_BASEURL}/update${type.charAt(0).toUpperCase() + type.slice(1)}`,
                payload,
                {
                    headers: { 'X-Csrf-Token': csrfToken },
                }
            );

            if (response.status === 200) {
                let successMessage = `${type.charAt(0).toUpperCase() + type.slice(1)} updated successfully`;
                if (type === 'profilePicture') {
                    successMessage = 'Profile picture successfully updated';
                } else if (type === 'deleteAccount') {
                    successMessage = 'Account deleted';
                    setTimeout(() => navigate('/home'), 2000); // Redirect after 2 seconds
                }
                switch (type) {
                    case 'username':
                        setUsernameMessage(successMessage);
                        break;
                    case 'password':
                        setPasswordMessage(successMessage);
                        break;
                    case 'profilePicture':
                        setProfilePictureMessage(successMessage);
                        break;
                    case 'deleteAccount':
                        setDeleteAccountMessage(successMessage);
                        break;
                    default:
                        break;
                }
            } else {
                const errorMessage = response.data.message || `Failed to update ${type}`;
                switch (type) {
                    case 'username':
                        setUsernameMessage(errorMessage);
                        break;
                    case 'password':
                        setPasswordMessage(errorMessage);
                        break;
                    case 'profilePicture':
                        setProfilePictureMessage(errorMessage);
                        break;
                    case 'deleteAccount':
                        setDeleteAccountMessage(errorMessage);
                        break;
                    default:
                        break;
                }
            }
        } catch (error) {
            console.error(`Error updating ${type}:`, error);
            const errorMessage = error.response?.data?.message || 'An error occurred. Please try again later.';
            switch (type) {
                case 'username':
                    setUsernameMessage(errorMessage);
                    break;
                case 'password':
                    setPasswordMessage(errorMessage);
                    break;
                case 'profilePicture':
                    setProfilePictureMessage(errorMessage);
                    break;
                case 'deleteAccount':
                    setDeleteAccountMessage(errorMessage);
                    break;
                default:
                    break;
            }
        }
    };

    const handleProfilePictureSelect = (pictureName) => {
        setSelectedProfilePicture(pictureName);
        setFormData({ ...formData, profilePicture: pictureName });
    };

    const handleConfirmProfilePicture = () => {
        handleSubmit('profilePicture');
        setShowProfilePictureModal(false); // Close the modal after selection
    };

    const handleDeleteAccount = () => {
        handleSubmit('deleteAccount');
        setShowDeleteAccountModal(false); // Close the modal after deletion
    };

    return (
        <section className="settings-page container py-4 py-xl-5">
            <div className="row">
                {/* Username Change Section */}
                <div className="col-lg-6 mb-4">
                    <div className="card shadow-sm p-4">
                        <div className="d-flex align-items-center">
                            <FontAwesomeIcon icon={faUser} className="me-3" size="2x" />
                            <h4>Change Username</h4>
                        </div>
                        <Form onSubmit={(e) => { e.preventDefault(); handleSubmit('username'); }}>
                            <Form.Group className="mt-3">
                                <Form.Control
                                    type="text"
                                    placeholder="New Username"
                                    name="username"
                                    value={formData.username}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Button className="mt-3" variant="light" type="submit">Change Username</Button>
                            {usernameMessage && (
                                <div className="message-box-settings mt-3" role="alert">
                                    {usernameMessage}
                                </div>
                            )}
                        </Form>
                    </div>
                </div>

                {/* Password Change Section */}
                <div className="col-lg-6 mb-4">
                    <div className="card shadow-sm p-4">
                        <div className="d-flex align-items-center">
                            <FontAwesomeIcon icon={faKey} className="me-3" size="2x" />
                            <h4>Change Password</h4>
                        </div>
                        <Form onSubmit={(e) => { e.preventDefault(); handleSubmit('password'); }}>
                            <Form.Group className="mt-3">
                                <Form.Control
                                    type="password"
                                    placeholder="New Password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="mt-3">
                                <Form.Control
                                    type="password"
                                    placeholder="Confirm Password"
                                    name="confirmPassword"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Button className="mt-3" variant="light" type="submit">Change Password</Button>
                            {passwordMessage && (
                                <div className="message-box-settings mt-3" role="alert">
                                    {passwordMessage}
                                </div>
                            )}
                        </Form>
                    </div>
                </div>

                {/* Profile Picture Change Section */}
                <div className="col-lg-6 mb-4">
                    <div className="card shadow-sm p-4">
                        <div className="d-flex align-items-center">
                            <FontAwesomeIcon icon={faImage} className="me-3" size="2x" />
                            <h4>Change Profile Picture</h4>
                        </div>
                        <Button className="mt-3" variant="light" onClick={() => setShowProfilePictureModal(true)}>Select Profile Picture</Button>
                        {profilePictureMessage && (
                            <div className="message-box-settings mt-3" role="alert">
                                {profilePictureMessage}
                            </div>
                        )}
                    </div>
                </div>

                {/* Delete Account Section */}
                <div className="col-lg-6 mb-4">
                    <div className="card shadow-sm p-4">
                        <div className="d-flex align-items-center">
                            <FontAwesomeIcon icon={faTrashAlt} className="me-3" size="2x" />
                            <h4>Delete Account</h4>
                        </div>
                        <Button className="mt-3" variant="danger" onClick={() => setShowDeleteAccountModal(true)}>Delete Account</Button>
                        {deleteAccountMessage && (
                            <div className="message-box-settings mt-3" role="alert">
                                {deleteAccountMessage}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Profile Picture Modal */}
            <Modal show={showProfilePictureModal} onHide={() => setShowProfilePictureModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Profile Picture</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-wrap justify-content-center">
                        {profilePictures.map((picture, index) => (
                            <img
                                key={index}
                                src={picture.url} // Use URL from API
                                alt={`Profile ${index + 1}`}
                                className={`img-thumbnail m-2 ${selectedProfilePicture === picture.name ? 'selected-profile-picture' : ''}`}
                                style={{ width: '100px', height: '100px', cursor: 'pointer' }}
                                onClick={() => handleProfilePictureSelect(picture.name)}
                            />
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowProfilePictureModal(false)}>Close</Button>
                    <Button variant="primary" onClick={handleConfirmProfilePicture}>Confirm</Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Account Confirmation Modal */}
            <Modal show={showDeleteAccountModal} onHide={() => setShowDeleteAccountModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete your account? This action cannot be undone.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteAccountModal(false)}>Cancel</Button>
                    <Button variant="danger" onClick={handleDeleteAccount}>Delete Account</Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
};

export default SettingsPage;
