import React from 'react';

function BannerLight() {
    return (
        <section className="py-4 py-xl-5">
            <div className="container align-items-center">
                <div className="text-dark bg-primary border rounded border-0 border-light d-flex flex-column justify-content-between flex-lg-row p-4 p-md-5 align-items-center">
                    <div className="pb-2 pb-lg-1">
                        <h2 className="fw-bold mb-2">Think You Know Aussie Slang?</h2>
                        <p className="mb-0">Test your knowledge and challenge your mates in our fun and educational Aussie Slang quiz. Dive in now and see how well you can speak like a true Aussie!</p>
                        <a className="btn btn-light btn-lg glow-button mt-4" role="button" href={`${process.env.REACT_APP_BASEURL}/quiz`}>Get cracking!</a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BannerLight;

