import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCookieBite } from '@fortawesome/free-solid-svg-icons';

const CookiePolicy = () => {
    return (
        <div className="cookie-policy-container">
            <div className="cookie-policy-header">
                <FontAwesomeIcon icon={faCookieBite} className="cookie-icon" />
                <h1>Our Cookie Policy</h1>
            </div>
            <div className="cookie-policy-content">
                <p>
                    <strong>This website uses cookies</strong> to ensure you get the best experience. Cookies are small data files stored on your device when you visit a website. They help in various ways, such as keeping you logged in, remembering your preferences, and ensuring that the website functions correctly.
                </p>
                <p>
                    Some cookies are <strong>essential for the website to function</strong> and cannot be switched off. These cookies do not store any personal information and are only set in response to actions made by you, such as logging in or filling out forms.
                </p>
                <p>
                    For more detailed information on how we use cookies, please refer to our <Link to="/privacypolicy" className="privacy-link">full privacy policy</Link>.
                </p>
            </div>
        </div>
    );
};

export default CookiePolicy;
