import axios from 'axios';

export const fetchImages = async (name = '', category = '') => {
    try {
        // Construct the cache key based on the query parameters
        const cacheKey = `images_${name}_${category}`;

        // Check if the data is already in localStorage
        const cachedData = localStorage.getItem(cacheKey);
        if (cachedData) {
            console.log('Returning cached data');
            return JSON.parse(cachedData); // Return the cached data
        }

        // Construct the query parameters
        const params = new URLSearchParams();
        if (name) params.append('name', name);
        if (category) params.append('category', category);

        const queryString = params.toString() ? `?${params.toString()}` : '';

        // Make the backend call to fetch data
        const response = await axios.get(`${process.env.REACT_APP_BASEURL}/images${queryString}`);

        // Save the fetched data to localStorage
        localStorage.setItem(cacheKey, JSON.stringify(response.data));

        // Return the fetched data
        return response.data;
    } catch (error) {
        console.error('Error fetching images:', error);
        throw error;
    }
};
