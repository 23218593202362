import React from 'react';
import NavBar from './Components/NavBar';
import Scoreboard from './Components/Scoreboard';

function LeaderBoard() {
    return (
        <div>
            {/* Render NavBar and Scoreboard directly */}
            <NavBar />
            <Scoreboard />
        </div>
    );
}

export default LeaderBoard;
